import React, { useState, useRef } from "react";
import { MenuItem, Button, Menu } from "@mui/material";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import Icon from "@mdi/react";
import { mdiWeb } from "@mdi/js";
import { mdiMenuDown } from "@mdi/js";
import useSettings from "../../hooks/useSettings";
import useAuth from "../../hooks/useAuth";

const SelectLanguage = ({ page }) => {
  const { user } = useAuth();
  const { settings, saveSettings } = useSettings();
  const anchorRef = useRef(null);

  const [language, setLanguage] = React.useState(
    settings.language || user?.data?.locale || "ar"
  );
  const { t } = useTranslation();
  const [openMenu, setOpenMenu] = useState(false);

  const handleSaveDirection = (lang) => {
    const nextLang = lang === "ar" ? "ar" : "en";
    const dir = lang === "ar" ? "rtl" : "ltr";
    saveSettings({
      direction: dir,
      language: nextLang,
    });
    setLanguage(lang);
    i18n.changeLanguage(nextLang);
    localStorage.setItem("updateSettings", JSON.stringify(true));
    setOpenMenu(false);
  };
  const handleMenuOpen = () => {
    setOpenMenu(true);
  };
  const handleMenuClose = () => {
    setOpenMenu(false);
  };
  return (
    <div className={page !== "editorView" && "select-language-button"}>
      <Button
        ref={anchorRef}
        onClick={handleMenuOpen}
        startIcon={<Icon path={mdiWeb} size={1} />}
        endIcon={<Icon path={mdiMenuDown} size={1} />}
        className={
          page === "editorView" && "select-language-button-editor-view"
        }
      >
        {page !== "editorView" && t(settings.language)}
      </Button>
      <Menu
        id="select-language"
        elevation={6}
        anchorEl={anchorRef.current}
        open={openMenu}
        labelId="demo-simple-select-label"
        onClose={handleMenuClose}
        color="primary"
      >
        <MenuItem
          id="MenuItem-arabic-language"
          value={"ar"}
          onClick={() => handleSaveDirection("ar")}
        >
          {t("arabic")}
        </MenuItem>
        <MenuItem
          id="MenuItem-english-language"
          value={"en"}
          onClick={() => handleSaveDirection("en")}
        >
          {t("english")}
        </MenuItem>
      </Menu>
    </div>
  );
};
export default SelectLanguage;
