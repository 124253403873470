import React, { createContext, useReducer, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import axios from "axios";
import { Typography } from "@mui/material";
import useData from "../hooks/useData";
import config from "../utils/settings";
import { LineApi } from "../services/http";

const initialDataState = {
  displayPolygons: false,
  displayRegions: false,
  displayBaselines: false,
  editBaselines: false,
  addBaselines: false,
  selectedBaseline: [],
  selectedAnchorPoints: [],
  baselineIndex: null,
  imageBase64: null,
  polygons: [],
  regions: [],
  spellings: [],
  editSegmentationAction: null,
  history: [],
  redoStack: [],
  stageZoomLevel: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_DISPLAY_POLYGONS": {
      return {
        ...state,
        displayPolygons: action.payload,
      };
    }
    case "SET_DISPLAY_REGIONS": {
      return {
        ...state,
        displayRegions: action.payload,
      };
    }
    case "SET_DISPLAY_BASELINES": {
      return {
        ...state,
        displayBaselines: action.payload,
      };
    }
    case "SET_EDIT_BASELINES": {
      return {
        ...state,
        editBaselines: action.payload,
      };
    }
    case "SET_ADD_BASELINES": {
      return {
        ...state,
        addBaselines: action.payload,
      };
    }
    case "SET_SELECTED_BASELINE": {
      return {
        ...state,
        selectedBaseline: action.payload,
      };
    }
    case "SET_BASELINES": {
      return {
        ...state,
        baselines: action.payload,
      };
    }
    case "SET_POLYGONS": {
      return {
        ...state,
        polygons: action.payload,
      };
    }
    case "SET_REGIONS": {
      return {
        ...state,
        regions: action.payload,
      };
    }
    case "SELECT_ANCHOR_POINTS": {
      return {
        ...state,
        selectedAnchorPoints: action.payload,
      };
    }
    case "SET_BASELINE_INDEX": {
      return {
        ...state,
        baselineIndex: action.payload,
      };
    }
    case "SET_IMAGE_BASE64": {
      return {
        ...state,
        imageBase64: action.payload,
      };
    }
    case "GET_SPELLING_TEXT": {
      const { spellings } = action.payload;
      return {
        ...state,
        spellings,
      };
    }
    case "SET_EDIT_SEGMENTATION_ACTION": {
      return {
        ...state,
        editSegmentationAction: action.payload,
      };
    }
    case "SET_HISTORY": {
      return {
        ...state,
        history: action.payload,
      };
    }
    case "SET_REDO_STACK": {
      return {
        ...state,
        redoStack: action.payload,
      };
    }
    case "SET_STAGE_ZOOM_LEVEL": {
      return {
        ...state,
        stageZoomLevel: action.payload,
      };
    }

    default: {
      return { ...state };
    }
  }
};
const PageContext = createContext({
  ...initialDataState,
  setDisplayPolygons: () => Promise.resolve(),
  setDisplayRegions: () => Promise.resolve(),
  setDisplayBaselines: () => Promise.resolve(),
  setEditBaselines: () => Promise.resolve(),
  setAddBaselines: () => Promise.resolve(),
  setSelectedBaseline: () => Promise.resolve(),
  setBaselines: () => Promise.resolve(),
  selectAnchorPoints: () => Promise.resolve(),
  deleteSelectedAnchorPoints: () => Promise.resolve(),
  updateSelectedBaseline: () => Promise.resolve(),
  setImageBase64ToEditSegmentation: () => Promise.resolve(),
  setPolygons: () => Promise.resolve(),
  setRegions: () => Promise.resolve(),
  addNewBaseline: () => Promise.resolve(),
  deleteBaseline: () => Promise.resolve(),
  getSpellingText: () => Promise.resolve(),
  resetSpellings: () => Promise.resolve(),
  setEditSegmentationAction: () => Promise.resolve(),

  setHistory: () => Promise.resolve(),
  setRedoStack: () => Promise.resolve(),
  setAction: () => Promise.resolve(),
  undo: () => Promise.resolve(),
  redo: () => Promise.resolve(),
  updateLineRotationStatus: () => Promise.resolve(),
  setStageZoomLevel: () => Promise.resolve(),
});
export const PageProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialDataState);
  const { getLines, setNewText, setCanShowDialogLeavingPage, lines, setLines } =
    useData();

  const setHistory = (array) => {
    dispatch({
      type: "SET_HISTORY",
      payload: array,
    });
  };
  const setRedoStack = (array) => {
    dispatch({
      type: "SET_REDO_STACK",
      payload: array,
    });
  };
  const setAction = () => {
    setHistory([...state.history, lines]);
    setRedoStack([]);
  };

  const undo = () => {
    if (state.history.length > 0) {
      const previousState = state.history[state.history.length - 1];
      setHistory(state.history.slice(0, -1));
      setRedoStack([lines, ...state.redoStack]);
      setLines(previousState);
      setEditSegmentationAction("undo");
    }
  };
  const redo = () => {
    if (state.redoStack.length > 0) {
      const nextState = state.redoStack[0];
      setRedoStack(state.redoStack.slice(1));
      setHistory([...state.history, lines]);
      setLines(nextState);
      setEditSegmentationAction("redo");
    }
  };

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const getSnackbar = (message, status, description) => {
    enqueueSnackbar(
      <Typography variant="body2" className="snackbar-msg">
        {t(message)}
        {description ? (
          <>
            <br />
            {t(description)}
          </>
        ) : null}
      </Typography>,
      {
        variant: status,
        autoHideDuration:
          status === "error" ||
          message === "successUploadFile" ||
          message === "successTranscriptionPages"
            ? null
            : 5000,
      }
    );
  };
  const setEditSegmentationAction = (action) => {
    dispatch({
      type: "SET_EDIT_SEGMENTATION_ACTION",
      payload: action,
    });
  };
  const setDisplayPolygons = (booleen) => {
    dispatch({
      type: "SET_DISPLAY_POLYGONS",
      payload: booleen,
    });
    localStorage.setItem("displayPolygons", JSON.stringify(booleen));
  };
  const setDisplayRegions = (booleen) => {
    dispatch({
      type: "SET_DISPLAY_REGIONS",
      payload: booleen,
    });
    localStorage.setItem("displayRegions", JSON.stringify(booleen));
  };
  const setDisplayBaselines = (booleen) => {
    dispatch({
      type: "SET_DISPLAY_BASELINES",
      payload: booleen,
    });
    localStorage.setItem("displayBaselines", JSON.stringify(booleen));
  };
  const setEditBaselines = (booleen) => {
    dispatch({
      type: "SET_EDIT_BASELINES",
      payload: booleen,
    });
    localStorage.setItem("editBaselines", JSON.stringify(booleen));
  };
  const setAddBaselines = (booleen) => {
    dispatch({
      type: "SET_ADD_BASELINES",
      payload: booleen,
    });
  };
  const setSelectedBaseline = (baseline, indexOfBaseline) => {
    dispatch({
      type: "SET_SELECTED_BASELINE",
      payload: baseline,
    });
    dispatch({
      type: "SET_BASELINE_INDEX",
      payload: indexOfBaseline,
    });
  };
  const setBaselines = (array) => {
    dispatch({
      type: "SET_BASELINES",
      payload: array,
    });
  };
  const setPolygons = (array) => {
    dispatch({
      type: "SET_POLYGONS",
      payload: array,
    });
  };
  const setRegions = (array) => {
    dispatch({
      type: "SET_REGIONS",
      payload: array,
    });
  };

  const selectAnchorPoints = (array) => {
    dispatch({
      type: "SELECT_ANCHOR_POINTS",
      payload: array,
    });
  };
  const deleteSelectedAnchorPoints = (
    folderId,
    documentId,
    imageId,
    lineId,
    imageBase64,
    updatedBaselines,
    baselineIndex,
    selectedAnchorPoints,
    action
  ) => {
    setAction();
    const sortedIndices = selectedAnchorPoints?.slice().sort((a, b) => b - a);
    if (
      selectedAnchorPoints.length &&
      updatedBaselines[baselineIndex].length !==
        selectedAnchorPoints?.length + 1 &&
      action !== "deleteBaseline"
    ) {
      sortedIndices.forEach((pointKey) => {
        updatedBaselines[baselineIndex].splice(pointKey, 1);
      });
      updateSelectedBaseline(
        folderId,
        documentId,
        imageId,
        lineId,
        imageBase64,
        updatedBaselines[baselineIndex],
        baselineIndex
      );
    } else {
      setSelectedBaseline([], null);
      const newList = lines
        .filter((item) => item.id !== lineId)
        .map((line, index) => {
          return {
            text: line.text || "",
            id: line.id,
            polygon: line.polygon,
            index: line.index,
            order: index,
            imageID: line.imageID,
            baseline: line.baseline,
            history: line.history,
            isFlipped: line.isFlipped ?? false,
          };
        });
      setLines(newList);
      deleteBaseline(folderId, documentId, imageId, lineId);
    }
    setBaselines(updatedBaselines);
    selectAnchorPoints([]);
  };
  const updateSelectedBaseline = async (
    folderId,
    documentId,
    imageId,
    lineId,
    imageBase64,
    newBaseline,
    order
  ) => {
    setAction();
    const newBaselineOfArrays = newBaseline.map(({ x, y }) => [x, y]);
    let body = {
      image: imageBase64,
      newBaseline: newBaselineOfArrays,
      order: order,
    };
    setEditSegmentationAction("editBaseline");
    await axios
      .put(
        `${config.REACT_APP_BASE_URL}/folder/line/baseline/${lineId}/${imageId}`,
        body
      )
      .then(async (response) => {
        setCanShowDialogLeavingPage(true);
        setEditSegmentationAction(null);

        let updatedLine = {
          text: response.data.data.line.text,
          id: response.data.data.line._id,
          polygon: response.data.data.line.polygon,
          index: response.data.data.line.index,
          order: order,
          imageID: response.data.data.line.imageID,
          baseline: response.data.data.line.baseline,
          history: response.data.data.line.history,
        };
        let updatedLines = lines?.map((line) =>
          line.id === updatedLine.id ? updatedLine : line
        );
        setLines(updatedLines);
        setNewText([
          {
            index: response.data.data.line.index,
            lineId: response.data.data.line._id,
            newVersion: response.data.data.line.text,
            polygon: response.data.data.mask,
          },
        ]);
      })
      .catch(async (err) => {
        getSnackbar("errorEditBaseLine", "error");
        setEditSegmentationAction(null);
      });
  };

  const setImageBase64ToEditSegmentation = (string) => {
    dispatch({
      type: "SET_IMAGE_BASE64",
      payload: string,
    });
  };

  const addNewBaseline = async (
    folderId,
    documentId,
    imageId,
    imageBase64,
    newBaseline,
    updatedBaselines,
    isFlipped
  ) => {
    setAction();
    const newBaselineOfArrays = newBaseline.map(({ x, y }) => [x, y]);
    setEditSegmentationAction("addNewBaseline");
    let body = {
      image: imageBase64,
      newBaseline: newBaselineOfArrays,
      isFlipped,
    };
    await axios
      .post(
        `${config.REACT_APP_BASE_URL}/folder/line/baseline/${imageId}`,
        body
      )
      .then(async (response) => {
        setCanShowDialogLeavingPage(true);
        let newLines = lines;
        let newLine = {
          text: response.data.line.text || "",
          id: response.data.line._id,
          polygon: response.data.line.polygon,
          index: response.data.line.index,
          order: lines.length,
          imageID: response.data.line.imageID,
          baseline: response.data.line.baseline,
          history: response.data.line.history,
          isFlipped: response.data.line.isFlipped,
        };
        newLines.push(newLine);
        setLines(newLines);
        setEditSegmentationAction(null);
      })
      .catch(async (err) => {
        const newArray = updatedBaselines.slice(0, -1);
        setBaselines(newArray);
        getSnackbar("errorAddNewBaseLine", "error");
        setEditSegmentationAction(null);
      });
  };

  const deleteBaseline = async (folderId, documentId, imageId, lineId) => {
    setEditSegmentationAction("deleteBaseline");
    setSelectedBaseline([], null);
    await axios
      .delete(
        `${config.REACT_APP_BASE_URL}/folder/line/baseline/${lineId}/${imageId}`
      )
      .then(async (response) => {
        setCanShowDialogLeavingPage(true);
        setEditSegmentationAction(null);
      })
      .catch(async (err) => {
        getLines(folderId, documentId, imageId, "getLinesAfterEdirBaseline");
        setEditSegmentationAction(null);
        getSnackbar("errorDeleteBaseLine", "error");
      });
  };

  const updateLineRotationStatus = async (lineIndex) => {
    setEditSegmentationAction("updateLineRotationStatus");
    lines[lineIndex].isFlipped = !lines[lineIndex].isFlipped;
    setLines(lines);
    LineApi.updateLineRotationStatus(
      lines[lineIndex].id,
      lines[lineIndex].imageID,
      lines[lineIndex].isFlipped
    )
      .then(() => {
        setCanShowDialogLeavingPage(true);
        setEditSegmentationAction(null);
      })
      .catch(async (err) => {
        setEditSegmentationAction(null);
      })
      .finally(() => {
        setEditSegmentationAction(null);
      });
  };

  const setStageZoomLevel = (object) => {
    dispatch({
      type: "SET_STAGE_ZOOM_LEVEL",
      payload: object,
    });
  };
  useEffect(() => {
    const displayPolygons = JSON.parse(localStorage.getItem("displayPolygons"));
    const displayRegions = JSON.parse(localStorage.getItem("displayRegions"));
    const displayBaselines = JSON.parse(
      localStorage.getItem("displayBaselines")
    );
    const editBaselines = JSON.parse(localStorage.getItem("editBaselines"));

    dispatch({
      type: "SET_DISPLAY_POLYGONS",
      payload: displayPolygons,
    });
    dispatch({
      type: "SET_DISPLAY_REGIONS",
      payload: displayRegions,
    });
    dispatch({
      type: "SET_DISPLAY_BASELINES",
      payload: displayBaselines,
    });
    dispatch({
      type: "SET_EDIT_BASELINES",
      payload: editBaselines,
    });

    // eslint-disable-next-line
  }, []);

  return (
    <PageContext.Provider
      value={{
        ...state,
        setDisplayPolygons,
        setDisplayRegions,
        setDisplayBaselines,
        setEditBaselines,
        setAddBaselines,
        setSelectedBaseline,
        setBaselines,
        selectAnchorPoints,
        deleteSelectedAnchorPoints,
        updateSelectedBaseline,
        setImageBase64ToEditSegmentation,
        setPolygons,
        setRegions,
        addNewBaseline,
        deleteBaseline,
        setEditSegmentationAction,
        setHistory,
        setRedoStack,
        setAction,
        undo,
        redo,
        updateLineRotationStatus,
        setStageZoomLevel,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export default PageContext;
